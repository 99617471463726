import React from 'react'
import { Link } from 'raviger'

import { setRecentCache, unifyPlatformTitle } from '../util/cache-operations'
import { S3URL } from '../util/image-helpers'

export default React.forwardRef((props, ref) => {
  const isPlatform = props.name && props.name !== props.devPortalProjectName
  const displayName = isPlatform
    ? props.name
    : props.devPortalProjectName

  return (
    <Link
      onClick={() => setRecentCache(props)}
      href={`/${unifyPlatformTitle(props.devPortalProjectName || props.name)}`}
    >
      <div
        ref={ref}
        data-href={`/${unifyPlatformTitle(props.devPortalProjectName || props.name)}`}
        className={`product-card ${props.className || ''}`}
      >
        {props.logo && <span className='card-img'>
          <img alt='' src={S3URL(props.logo)} />
        </span>}
        <span className='card-name epic-font-brand'>{displayName.replace(/-/g, ' ')}</span>
      </div>
      {props.hasSummary && props.summary}
    </Link>
  )
})
