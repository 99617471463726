/* eslint-disable */
import React, { useState } from 'react'
import { NavBar } from '@nike/epic-react-ui'
import { Link } from 'raviger'
import logo from '../assets/logo.png'
import config from '../config'
import http from '../util/http'
import { HomeSearchDocs } from '../components';

function Navigation ({ email }) {
  const [searchVal, setSearchVal] = useState('')

  const avatarConfig = {
    imageLink: '/',
    dropDownLinks: [
      { text: 'Manage', path: '/' }
    ]
  }

  const SupportDropdown = ({ title, links }) => (
    <div className='dropdown-section'>
      <div className='dropdown-button'>{title}</div>
      <ul className='support-dropdown-content'>
        {links.map(({ text, path }) => (
          <li className='dropdown-link' key={path}>
            <a target='blank' href={path}>{text}</a>
          </li>
        ))}
      </ul>
    </div>
  )

  return (
    <NavBar
      name='Docs'
      logo={logo}
      rootPath="/"
      routes={[
        {
          text: 'Guide',
          path: '/guide'
        }
      ]}
      RouterLink={Link}
      className='navigation'
      aside={window.location.pathname !== '/' && <HomeSearchDocs nav />}
      // slack={slackConfig}
      // aside={
      //   <div className='aside-group'>
      //     {/* <TextInput
      //       className='epic-font-base'
      //       onChange={e => setSearchVal(e.target.value)}
      //       border={false}
      //       value={searchVal}
      //       placeholder='Search Documentation...'
      //     /> */}
      //     <SupportDropdown
      //       title='Support'
      //       links={[
      //         { text: 'Slack', path: config.consoleSlackChannel },
      //         { text: 'Console', path: config.platformConsoleUrl }
      //       ]}
      //     />
      //   </div>
      // }
      // onChange={onChange}
      // previewItems={previewItems}
      // avatar={{
      //   email,
      //   className: 'navbar-avatar',
      //   routes: avatarConfig
      // }}
    />
  )
}

export default Navigation
