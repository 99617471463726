import React from 'react'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider
} from '@apollo/client'

import { setContext } from '@apollo/link-context'
import config from '../config'

const httpLink = createHttpLink({
  uri: config.platformConsoleApi
})

const setAuthLink = authorization => setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization
    }
  }
})

export default function ({ children, auth }) {
  const authLink = setAuthLink(auth)
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink)
  })

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
