import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Provider as TitleProvider } from 'react-doc-title'
import { ToastContainer } from 'react-toastify'

import { useRoutes, usePath, Redirect } from 'raviger'
import {
  initializeUserAuth,
  hasAuthCheckFinished,
  selectLoginError,
  selectAccessToken,
  selectEmail,
  loginUser,
  logoutUser
} from '@nike/oauth-client'
import { Button } from '@nike/epic-react-ui'

import ConsoleProvider from './ConsoleProvider'
import routes from './routes'
import NotFoundPage from './NotFoundPage'
import OfflineBanner from '../offline/OfflineBanner'
import { LoginRequired } from '../auth'
import Spinner from '../components/Spinner'
import Error from '../components/Error'
import Navigation from './Navigation'
import redirectMap from '../redirects'

const App = ({
  initializeUserAuth,
  loginError,
  loginFinished,
  loginUser,
  email,
  token
}) => {
  // eslint-disable-next-line
  useEffect(() => { initializeUserAuth() }, [])

  const routeResult = useRoutes(routes) || <NotFoundPage />
  const path = usePath()

  if (loginError) {
    return (
      <div>
        <Error error={loginError} />
        <Button onClick={loginUser}>Login</Button>
      </div>
    )
  }

  if (path in redirectMap) return <Redirect to={redirectMap[path]} />

  if (!loginFinished) {
    return <Spinner large />
  }

  return (
    <ConsoleProvider auth={token}>
      <TitleProvider title='EP Docs'>
        <span>
          <OfflineBanner />
          <Navigation email={email} />
          <LoginRequired>
            <div>
              {routeResult}
            </div>
          </LoginRequired>
        </span>
        <ToastContainer />
      </TitleProvider>
    </ConsoleProvider>
  )
}

export default connect(
  state => ({
    loginFinished: hasAuthCheckFinished(state),
    loginError: selectLoginError(state),
    email: selectEmail(state),
    token: selectAccessToken(state)
  }),
  { initializeUserAuth, loginUser, logoutUser }
)(App)
