import React from 'react'

import Card from './Card'
// import { unifyPlatformTitle } from '../util/cache-operations'

export default function ({ products, starred, cache, hasSummary }) {
  const className = `layout card-layout ${hasSummary ? 'has-summary epic-font-base' : ''}`
  if (!cache) {
    return (
      <div className={className}>
        {products.map(product => <Card hasSummary={hasSummary} key={product.name} {...product} />)}
      </div>
    )
  }

  const cachePlatforms = Object.values(cache).map(project => {
    const cacheProducts = products.filter(product => {
      return product.devPortalProjectName === project.devPortalProjectName
    })
    if (cacheProducts.length) {
      return cacheProducts[0]
    } else {
      return project
    }
  })

  return (
    <div className={className}>
      {cachePlatforms.map(product => (
        <Card
          starred={starred}
          hasSummary={hasSummary}
          key={product}
          {...product}
        />
      ))}
    </div>
  )
}
