/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Container, ToggleSwitch } from '@nike/epic-react-ui'

import config from '../config'
import Query from '../graphql/Query.js'
import { HOME_QUERY } from '../graphql/queries'
import { tryParse } from '../util/cache-operations'

import {
  Hero,
  HomeSearchDocs,
  HomeSearchPlatforms,
  PlatformList,
  CategoryList,
  CollapseSection
} from '../components/'

function HomePage () {
  const variables = {
    teams: []
  }
  return (
    <div className='content-wrapper'>
      <Hero />
      <Query
        loader
        query={HOME_QUERY}
        variables={variables}
        render={data => <HomeContent data={data} />}
      />
    </div>
  )
}

function HomeContent ({ data }) {
  const { getActiveCategories, products } = data
  const [searchType, setSearchType] = useState('platforms')

  const recents = tryParse(
    localStorage.getItem(config.recentPlatformStorageItem)
  )

  const starred = tryParse(
    localStorage.getItem(config.savedPlatformStorageItem)
  )

  return (
    <Container className='container home-body'>
      <ToggleSwitch
        className='home-toggle'
        options={[
          { label: 'Platforms', value: 'platforms' },
          { label: 'Documents', value: 'docs' }
        ]}
        value={searchType}
        onChange={setSearchType}
      />
      {searchType === 'docs' ? (
        <HomeSearchDocs
          whitelist={products.map(prod => prod.devPortalProjectName).join(',')}
          products={products}
        />
      ) : (
        <HomeSearchPlatforms
          products={products}
        />
      )}
      <div className='home-content'>
        {recents && <CollapseSection title='Recent'>
          <PlatformList cache={recents} products={products} />
        </CollapseSection>}
        {starred && <CollapseSection title='Favorites'>
          <PlatformList starred cache={starred} products={products} />
        </CollapseSection>}
        {products.length ? <CollapseSection nested title='Platforms'>
          <CategoryList products={products} categories={getActiveCategories} />
        </CollapseSection> : null}
      </div>
    </Container>
  )
}

export default HomePage
