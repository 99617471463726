import Fuse from 'fuse.js'

const defaultOptions = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.16,
  location: 0,
  distance: 500,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    { name: 'name', weight: 0.2 },
    { name: 'categories', weight: 0.2 },
    { name: 'summary', weight: 0.1 },
    { name: 'description', weight: 0.1 },
    { name: 'features', weight: 0.1 },
    { name: 'roadmap.Q1.featureList', weight: 0.025 },
    { name: 'roadmap.Q2.featureList', weight: 0.025 },
    { name: 'roadmap.Q3.featureList', weight: 0.025 },
    { name: 'roadmap.Q4.featureList', weight: 0.025 },
    { name: 'useCases.summary', weight: 0.1 },
    { name: 'useCases.markdown', weight: 0.05 },
    { name: 'adopters', weight: 0.05 }
  ]
}

const platformFilters = (list, text = '', filters = [], options = defaultOptions) => {
  if (!list) list = []
  if (text === '' && filters.length === 0) {
    return list
  } else {
    let results = list
    if (filters.length > 0) {
      const stageResults = results.filter(r => filters.filter(f => r.stage && r.stage.toUpperCase() === f.value.toUpperCase()).length)
      const tagResults = results = results.filter(r => filters.filter(f => r.tags && r.tags.indexOf(f.value) !== -1).length)

      results = [...stageResults, ...tagResults]
    }

    if (text !== '') {
      var fuse = new Fuse(results, options) // 'list' is the item array
      results = fuse.search(text)
    }

    return results.filter((v, i, self) => self.indexOf(v) === i)
  }
}

export default platformFilters
