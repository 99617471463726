import React, { useState, useEffect } from 'react'
import { ActionInput, Button, Icon } from '@nike/epic-react-ui'
import { navigate, Link } from 'raviger'

import productFilters from '../util/product-filter'
import http from '../util/http'
import config from '../config'
import Query from '../graphql/Query'
import { HOME_SEARCH_QUERY } from '../graphql/queries'
import { setRecentCache, unifyPlatformTitle } from '../util/cache-operations'

const WHITELISTED_PLATFORMS = ',berlioux,ur,platform-console'

export default function (props) {
  if (!props.whitelist) {
    const variables = {
      teams: []
    }
    return (
      <Query
        query={HOME_SEARCH_QUERY}
        variables={variables}
        render={data => {
          const whitelist = data && data.products
            ? data.products.map(prod => prod.devPortalProjectName)
            : ''

          return <HomeSearch whitelist={whitelist} {...props} />
        }}
      />
    )
  } else {
    return <HomeSearch {...props} />
  }
}

function HomeSearch ({ products, nav, whitelist }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [searchVal, setSearchVal] = useState('')
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchItems, setSearchItems] = useState(false)
  const [pending, setPending] = useState(false)
  const platforms = productFilters(products, searchVal)

  const debouncedSearchVal = useDebounce(searchVal, 400)

  function submit (e) {
    e.preventDefault()
    if (searchItems.length && !!searchVal) {
      if (activeIndex > 0) {
        const cacheName = getCacheName(platforms, searchItems[activeIndex])

        setRecentCache({ name: cacheName, devPortalProjectName: searchItems[activeIndex].projectName })
        navigate(
          `/${unifyPlatformTitle(searchItems[activeIndex].projectName)}${searchItems[activeIndex].documentPath}?version=${searchItems[activeIndex].versionName}`
        )
      } else {
        const cacheName = getCacheName(platforms, searchItems[0])
        setRecentCache({ name: cacheName, devPortalProjectName: searchItems[0].projectName })
        navigate(
          `/${unifyPlatformTitle(searchItems[0].projectName)}${searchItems[0].documentPath}?version=${searchItems[0].versionName}`
        )
      }
    }
  }

  useEffect(() => {
    async function fetchData (val) {
      try {
        setPending(true)
        const searchPlatforms = `${whitelist}${WHITELISTED_PLATFORMS}`
        const response = await http.get(
          `${config.docsApi}/documents?q=${val}&projectNames=${searchPlatforms}`
        )
        setSearchItems(response.items.filter(item => item.projectName !== 'EP Docs'))
      } catch (error) {
        console.log(error)
      } finally {
        setPending(false)
      }
    }

    if (debouncedSearchVal) {
      setSearchOpen(true)
      fetchData(debouncedSearchVal)
    } else {
      setSearchOpen(false)
    }
  }, [debouncedSearchVal, whitelist])

  function onChange (e) {
    const val = e.target.value
    setSearchVal(val)
    if (!val) {
      setSearchOpen(false)
      setSearchItems([])
    }
  }

  function onKeyDown (e) {
    const resultsLength = searchItems.length

    const UP_ARROW_KEY_CODE = 38; const DOWN_ARROW_KEY_CODE = 40
    if (e.keyCode === UP_ARROW_KEY_CODE && activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    } else if (e.keyCode === DOWN_ARROW_KEY_CODE && activeIndex < resultsLength - 1) {
      setActiveIndex(activeIndex + 1)
    }

    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault()
    }

    if (e.key === 'Escape') {
      setSearchOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  })

  function focusInCurrentTarget ({ relatedTarget, currentTarget }) {
    if (relatedTarget === null || relatedTarget === undefined) return false
    let node = relatedTarget.parentNode
    while (node !== null) {
      if (node === currentTarget) return true
      node = node.parentNode
    }
    return false
  }

  function handleOnBlur (e) {
    if (!focusInCurrentTarget(e)) {
      setSearchOpen(false)
    }
  }

  function resetSearch () {
    setSearchVal('')
    setSearchOpen(false)
    setActiveIndex(0)
  }

  return (
    <form onBlur={handleOnBlur} onSubmit={submit} className={nav ? 'nav-search' : 'home-search'}>
      <span className='home-search-group'>
        <ActionInput
          isPending={pending}
          placeholder='Search Documentation...'
          ariaLabel='platform-search-input'
          className={`home-search-input ${searchOpen ? 'open' : ''}`}
          value={searchVal}
          onChange={onChange}
          leftButton
        >
        </ActionInput>
        {searchVal && (
          <Button onClick={resetSearch} className='remove'>
            <Icon fontSize='18px' type='close-x' />
          </Button>
        )}
      </span>
      {searchVal && <SearchResults
        pending={pending}
        searchItems={searchItems}
        platforms={platforms}
        open={searchOpen}
        escapeToExitSearch={handleOnBlur}
        activeIndex={activeIndex}
        resetActiveIndex={() => setActiveIndex(0)}
      />}
    </form>
  )
}

export function useDebounce (value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

function SearchResults ({ searchItems, open, onClick, activeIndex, pending, platforms }) {
  const [hoverIsActive, setHoverIsActive] = useState(false)
  const listClass = `search-results ${open ? 'open' : ''}`

  if (!searchItems || searchItems.length < 1) {
    return (
      <ul className={listClass}>
        <li className='search-result no-results'><span>{pending ? 'Searching...' : 'No results.'}</span></li>
      </ul>
    )
  }

  return (
    <ul className={listClass}>
      {searchItems.map((result, i) => (
        <li
          onMouseOver={() => setHoverIsActive(true)}
          onMouseLeave={() => setHoverIsActive(false)}
          key={`platform-${result.name}-${i}`}
          className={`search-result ${hoverIsActive ? 'hover-enabled' : ''} ${activeIndex === i ? 'active-index' : ''}`}
          onClick={onClick}>
          <DropDownItem key={result.title} platforms={platforms} result={result} />
        </li>
      ))}
    </ul>
  )
}

function getCacheName (platforms, result) {
  const linkedPlatforms = platforms.filter(platform => platform.devPortalProjectName === result.projectName)
  return linkedPlatforms.length
    ? linkedPlatforms[0].name
    : result.projectName
}

function DropDownItem ({ result, platforms }) {
  const docPath = result.documentPath.startsWith('/')
    ? result.documentPath
    : `/${result.documentPath}`
  const cacheName = getCacheName(platforms, result)
  return (
    <Link
      className='dropdown-item'
      onClick={() => setRecentCache({ name: cacheName, devPortalProjectName: result.projectName })}
      href={`/${result.projectName}${docPath}`}
    >
      Document:<span className='dropdown-bold epic-font-brand'>{result.title}</span><br></br>
      Project:<span className='dropdown-bold epic-font-brand'>{result.projectName}</span>
      Version:<span className='dropdown-bold epic-font-brand'>{result.versionName}</span>
    </Link>
  )
}
