import React, { useState, lazy, Suspense } from 'react'
import { Link, navigate } from 'raviger'
import { useSelector } from 'react-redux'
import { Title } from 'react-doc-title'
import { toast } from 'react-toastify'
import { Button, Spinner } from '@nike/epic-react-ui'
import { selectAccessToken } from '@nike/oauth-client'

import {
  setSavedCache,
  parseStorageItem,
  unifyPlatformTitle
} from '../util/cache-operations'
import config from '../config'

const Doctree = lazy(() => import('@nike/docs-client'))

export default function ({ platform, isGuide }) {
  const token = useSelector(selectAccessToken)
  const [isSaved, setIsSaved] = useState(checkForSavedPlatform(platform))

  function renderAside () {
    return platform && platform !== 'EP Docs' ? (
      <Button
        className='star-button'
        onClick={() => {
          setSavedToCache(platform)
          setIsSaved(!isSaved)
        }}
        small
      >
        <i className={`${isSaved ? 'saved-star' : ''} fa fa-star`}></i>
      </Button>
    ) : null
  }

  return (
    <>
      <Title string={
        platform ? `${platform.slice(0, 1).toUpperCase()}${platform.slice(1)}` : ''
      } />
      <Suspense fallback={<Spinner className='query-spinner' large />}>
        <Doctree
          Link={Link}
          onNavigate={navigate}
          token={token}
          projectName={platform}
          homeBreadCrumbPath='/'
          homeBreadCrumbText='Home'
          breadCrumbsAside={renderAside()}
          projectAlias={isGuide ? 'guide' : null}
          basePath={`/${isGuide ? 'guide' : encodeURIComponent(platform)}`}
        />
      </Suspense>
    </>
  )
}

function checkForSavedPlatform (platform) {
  const existing = parseStorageItem(config.savedPlatformStorageItem, null)
  return existing && existing[unifyPlatformTitle(platform)]
}

function setSavedToCache (name) {
  const existed = setSavedCache({ name })
  if (existed) {
    toast.success('Platform Removed.', {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  } else {
    toast.success('Platform saved.', {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  }
}
