import React from 'react'
import { useQuery } from '@apollo/client'
import { Spinner } from '@nike/epic-react-ui'

function Query ({ query, render, loader, ...props }) {
  const _variables = {
    pollInterval: 0,
    fetchPolicy: 'cache-and-network',
    ...props
  }

  const { loading, error, data } = useQuery(query, _variables)

  if (loading && !data) {
    if (loader) {
      return <Spinner className='query-spinner' large />
    } else {
      return render(null)
    }
  }

  if (error) {
    console.error(`Error! ${error.message}`)
    render(`Error! ${error.message}`)
  }

  if (!data) return null

  return render(data)
}

export const withAppQuery = Component => props => {
  return (
    <Query {...props}
      render={data => <Component data={data} />}
    />
  )
}

export default Query
