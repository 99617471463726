import merge from 'deepmerge'

// Console API
const platformConsoleApi = id => `https://${id}.appsync-api.us-west-2.amazonaws.com/graphql`

const base = {
  appName: 'platform-console-docs-site',
  appFullName: 'platform-console-docs-site',
  oAuth: {
    client_id: 'nike.niketech.platformconsole-docs-site',
    response_type: 'authorization_code',
    scope: 'profile openid email'
  },
  recentPlatformStorageItem: 'PlatformConsoleDocsRecents',
  savedPlatformStorageItem: 'PlatformConsoleDocsSaved',
  consoleSlackChannel: 'slack://channel?team=T0XT0BQ3V&id=CC5FN7AMV',
  platformConsoleUrl: 'https://console.platforms.nike.com'
}

const lab = merge(base, {
  apiUrl: '/dev',
  oAuth: {
    redirect_uri: window.location.origin,
    authorization: 'https://api.aegis.nikecloud.com/v1/qa/authorize',
    token: 'https://api.aegis.nikecloud.com/v1/qa/token'
  },
  docsApi: 'https://api-developer-dev.lab.niketech.com/v1',
  platformConsoleApi: platformConsoleApi('v4axgprgmndrhlv7p6h5tzb3wu')
})

// site is https://dls1y4fz04sov.cloudfront.net/
const dev = merge(base, {
  apiUrl: '/dev',
  oAuth: {
    redirect_uri: window.location.origin,
    authorization: 'https://api.aegis.nikecloud.com/v1/qa/authorize',
    token: 'https://api.aegis.nikecloud.com/v1/qa/token'
  },
  docsApi: 'https://api-developer.preprod.niketech.com/v1',
  platformConsoleApi: platformConsoleApi('v4axgprgmndrhlv7p6h5tzb3wu')
})

const prod = merge(base, {
  oAuth: {
    redirect_uri: window.location.origin,
    authorization: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize',
    token: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token'
  },
  docsApi: 'https://api-developer.niketech.com/prod/v1',
  platformConsoleApi: platformConsoleApi('w2kmkzgy4nctlku5hxatnoohre')
})

const isProd = () => (
  window.location.origin.includes('//docs.platforms.nike.com') ||
  window.location.origin.includes('//ep-docs.platforms.nike.com')
)

let config = dev
if (isProd()) {
  config = prod
} else if (
  window.location.origin.includes('localhost') ||
  window.location.origin.includes('d1ofxh1txb2n49')
) {
  config = lab
}

export default config
