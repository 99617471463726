import React from 'react'
import { Container } from '@nike/epic-react-ui'

import SVG from '../assets/heroSVG'

export default function () {
  return (
    <div className='hero'>
      <Container className='container hero-content'>
        <SVG />
        <h1 className='epic-font-brand'>
          EP Documentation
        </h1>
      </Container>
    </div>
  )
}
