import gql from 'graphql-tag'
import {
  PlatformBriefFrag,
  Fragments,
  PlatformFilterableFrag
} from './fragments'

export const HOME_QUERY = gql`
  query($teams: [String]!, $allCategories: Boolean) {
    products(teams: $teams, filterDocs: true) {
      __typename
      ...PlatformFilterableFrag
      platformAccess {
        __typename
        type
      }
      integratedPlatform {
        ...IntegratedPlatformFrag
      }
    }
    getActiveCategories(all: $allCategories, teams: $teams, filterDocs: true) {
      label
      value
    }
  }
  ${PlatformFilterableFrag}
  ${Fragments.integratedPlatform}
`

export const HOME_SEARCH_QUERY = gql`
  query($teams: [String]!) {
    products(teams: $teams, filterDocs: true) {
      __typename
      id
      name
      devPortalProjectName
    }
  }
`

export const searchQuery = gql`
  query($teams: [String]!, $includeUnavailable: Boolean) {
    products(teams: $teams, includeUnavailable: $includeUnavailable) {
      __typename
      ...PlatformFilterableFrag
    }
  }
  ${PlatformFilterableFrag}
`

export const platformBriefQueryAll = gql`
  query($teams: [String]!, $includeUnavailable: Boolean) {
    products(teams: $teams, includeUnavailable: $includeUnavailable) {
      __typename
      ...PlatformBriefFrag
    }
  }
  ${PlatformBriefFrag}
`

export const ACTIVE_CATEGORIES = gql`
  query getActiveCategories($all: Boolean, $teams: [String]!) {
    getActiveCategories(all: $all, teams: $teams) {
      label
      value
    }
  }
`

export const PLATFORM_DETAILS = gql`
  query platformByName($name: String!) {
    platformByName(name: $name) {
      __typename
      id
      name
      devPortalProjectName
      logo {
        __typename
        key
        bucket
        region
      }
    }
  }
`
