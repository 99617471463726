import gql from 'graphql-tag'

export const Fragments = {
  acquisition: gql`
    fragment AcquisitionFrag on AcquisitionStep {
      __typename
      title
      markdown
    }
  `,
  documentation: gql`
    fragment DocFrag on Document {
      __typename
      type
      link {
        __typename
        text
        url
      }
    }
  `,
  media: gql`
    fragment MediaFrag on Media {
      __typename
      type
      src {
        __typename
        bucket
        region
        key
      }
      url
    }
  `,
  releaseNotes: gql`
    fragment ReleaseNoteFrag on ReleaseNote {
      __typename
      link {
        __typename
        text
        url
      }
      dateReleased
    }
  `,
  testimonials: gql`
    fragment TestimonialFrag on Testimonial {
      __typename
      fName
      lName
      quote
      role
      group
      img {
        __typename
        bucket
        region
        key
      }
    }
  `,
  useCases: gql`
    fragment UseCaseFrag on UseCase {
      __typename
      summary
      markdown
      caseStudy
    }
  `,
  integratedPlatform: gql`
    fragment IntegratedPlatformFrag on IntegratedPlatform {
      __typename
      baseRoute
    }
  `,
  roadmap: gql`
    fragment RoadmapFrag on Roadmap {
      __typename
      detailedUrl
      Q1 {
        __typename
        year
        featureList
      }
      Q2 {
        __typename
        year
        featureList
      }
      Q3 {
        __typename
        year
        featureList
      }
      Q4 {
        __typename
        year
        featureList
      }
    }
  `
}

export const PlatformBriefFrag = gql`
  fragment PlatformBriefFrag on Platform {
    __typename
    id
    name
    logo {
      __typename
      bucket
      region
      key
    }
    summary
    url
    owningTeam
    available
    categories
    devPortalProjectName
    platformAccess {
      type
    }
    integratedPlatform {
      ...IntegratedPlatformFrag
    }
  }
  ${Fragments.integratedPlatform}
`

export const PlatformFilterableFrag = gql`
  fragment PlatformFilterableFrag on Platform {
    __typename
    ...PlatformBriefFrag
    description
    stage
    tags
    features
    devPortalProjectName
    acquisition {
      ...AcquisitionFrag
    }
  }
  ${PlatformBriefFrag}
  ${Fragments.acquisition}
`
