import React, { useState, useEffect } from 'react'
import { ActionInput, Button, Icon } from '@nike/epic-react-ui'
import { navigate } from 'raviger'

import { Card } from '../components'
import productFilters from '../util/product-filter'
import { unifyPlatformTitle } from '../util/cache-operations'

export default function ({ products }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [searchVal, setSearchVal] = useState('')
  const [searchOpen, setSearchOpen] = useState(false)
  const platforms = productFilters(products, searchVal)

  function submit (e) {
    e.preventDefault()
    if (platforms.length && !!searchVal) {
      if (activeIndex > 0) {
        navigate(`/${unifyPlatformTitle(platforms[activeIndex].devPortalProjectName)}`)
      } else {
        navigate(`/${unifyPlatformTitle(platforms[0].devPortalProjectName)}`)
      }
    }
  }

  function onChange (e) {
    if (e.target.value) {
      setSearchOpen(true)
    } else {
      setSearchOpen(false)
    }
    setActiveIndex(0)
    setSearchVal(e.target.value)
  }

  function onKeyDown (e) {
    const resultsLength = platforms.length

    const UP_ARROW_KEY_CODE = 38; const DOWN_ARROW_KEY_CODE = 40
    if (e.keyCode === UP_ARROW_KEY_CODE && activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    } else if (e.keyCode === DOWN_ARROW_KEY_CODE && activeIndex < resultsLength - 1) {
      setActiveIndex(activeIndex + 1)
    }

    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault()
    }

    if (e.key === 'Escape') {
      setSearchOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  })

  function focusInCurrentTarget ({ relatedTarget, currentTarget }) {
    if (relatedTarget === null || relatedTarget === undefined) return false
    let node = relatedTarget.parentNode
    while (node !== null) {
      if (node === currentTarget) return true
      node = node.parentNode
    }
    return false
  }

  function handleOnBlur (e) {
    if (!focusInCurrentTarget(e)) {
      setSearchOpen(false)
    }
  }

  function resetSearch () {
    setSearchVal('')
    setSearchOpen(false)
    setActiveIndex(0)
  }

  return (
    <form onBlur={handleOnBlur} onSubmit={submit} className='home-search-platforms'>
      <span className='home-search-group'>
        <ActionInput
          placeholder='Search Platforms (data movement, Entitlements, etc.)'
          ariaLabel='platform-search-input'
          className={`home-search-input ${searchOpen ? 'open' : ''}`}
          value={searchVal}
          onChange={onChange}
          leftButton
        >
        </ActionInput>
        {searchVal && (
          <Button onClick={resetSearch} className='remove'>
            <Icon fontSize='18px' type='close-x' />
          </Button>
        )}
      </span>
      {searchVal && <SearchResults
        platforms={platforms}
        open={searchOpen}
        escapeToExitSearch={handleOnBlur}
        activeIndex={activeIndex}
        resetActiveIndex={() => setActiveIndex(0)}
      />}
    </form>
  )
}

function SearchResults ({ platforms, open, onClick, activeIndex }) {
  const [hoverIsActive, setHoverIsActive] = useState(false)
  const listClass = `search-results ${open ? 'open' : ''}`

  if (platforms.length < 1) {
    return (
      <ul className={listClass}>
        <li className='search-result no-results'><span>No results.</span></li>
      </ul>
    )
  }

  return (
    <ul className={listClass}>
      {platforms.map((platform, i) => (
        <li
          onMouseOver={() => setHoverIsActive(true)}
          onMouseLeave={() => setHoverIsActive(false)}
          key={`platform-${platform.name}-${i}`}
          className={`search-result ${hoverIsActive ? 'hover-enabled' : ''} ${activeIndex === i ? 'active-index' : ''}`}
          onClick={onClick}>
          <Card className='dropdown-item' key={platform.id} {...platform} />
        </li>))}
    </ul>
  )
}
