import { combineReducers } from 'redux'
import { parseErrorMiddleware } from '@nike/redux-action-utils'
import { reducer as auth } from '@nike/oauth-client'
import offline from '../offline/reducer'
// import notifications from '../notifications/reducer'

const rootReducer = combineReducers({
  auth,
  parseErrorMiddleware,
  offline
  // notifications
})

export default rootReducer
