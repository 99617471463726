import React from 'react'
import { Title } from 'react-doc-title'
import Documentation from './Documentation'

export default function () {
  return (
    <>
      <Documentation isGuide platform='EP Docs' />
      <Title string='Guide'/>
    </>
  )
}
