import React from 'react'
import { Link, useQueryParams } from 'raviger'
import { Animated } from 'react-animated-css'

import { PlatformList } from '../components'
import { setRecentCache, unifyPlatformTitle } from '../util/cache-operations'

function CategoryList ({ categories, products }) {
  const [{ type }] = useQueryParams()
  return categories && products && (
    <div className='categories-container'>
      <Animated
        className={`${type ? '' : 'hidden'}`}
        animateOnMount={false}
        animationIn='slideInRight'
        animationOut='slideOutRight'
        isVisible={!!type}
      >
        <PlatformList
          hasSummary
          products={!type ? [] : filterProductsByCategory(products, categories, type)}
        />
      </Animated>
      <Animated
        className={`${!type ? '' : 'hidden'}`}
        animateOnMount={false}
        animationIn='slideInLeft'
        animationOut='slideOutLeft'
        isVisible={!type}
      >
        <div className='category-layout'>
          {categories && categories.map(cat => {
            return (
              <div key={cat.label} className='category-item'>
                <i style={{ marginRight: '7px' }} className={ICONS[cat.value]}></i>
                <div>
                  <Link className='epic-font-brand' href={`/?type=${cat.label}`}>
                    {cat.label}
                  </Link>
                  <div className='epic-font-base category-products'>
                    {filterProductsByCategory(products, categories, cat.label).map(product => {
                      return (
                        <Link
                          key={product.id}
                          onClick={() => setRecentCache(product)}
                          href={`/${unifyPlatformTitle(product.devPortalProjectName)}`}
                        >
                          {product.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Animated>
    </div>
  )
}

function filterProductsByCategory (products, categories, category) {
  return products.filter(product => (
    product.categories.includes(
      categories[categories.findIndex(cat => cat.label === category)].value)
  ))
}

const ICONS = {
  DATA_MOVEMENT: 'epic-icon epic-icon-steps',
  DATA_STORAGE: 'fa fa-database',
  EVENT_MANAGEMENT: 'fa fa-sitemap',
  ARCHITECTURE: 'fa fa-building',
  COLLAB_TOOLS: 'epic-icon epic-icon-collaborate',
  DEVELOPER_TOOLS: 'fa fa-briefcase',
  INTEGRATION: 'fa fa-connectdevelop',
  SECURITY_AND_IDENTITY: 'fa fa-shield',
  FINANCIAL_SERVICES: 'fa fa-credit-card-alt',
  CUSTOMER_ENGAGEMENT: 'fa fa-users',
  ETL: 'fa fa-tasks',
  METADATA: 'fa fa-database',
  WORKFLOW_MANAGEMENT: 'fa fa-bar-chart',
  BUILD_AUTOMATION: 'fa fa-magic',
  CLOUD_MANAGEMENT: 'fa fa-cloud',
  ANALYTICS_LEARNING: 'fa fa-line-chart',
  INTERNET_OF_THINGS: 'fa fa-microchip',
  CONTENT_DELIVERY: 'fa fa-envelope'
}

export default CategoryList
