import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import Root from './root/Root'
import * as serviceWorker from './serviceWorker'
import { watchOnlineState } from './offline/actions.js'

import './stylus/app.styl'
import '@nike/epic-react-ui/dist/styles/main.css'
import 'react-toastify/dist/ReactToastify.css'
import configureStore from './root/store.js'
import config from './config'

if (!localStorage.getItem('DOCS_CACHE_VERSION')) {
  localStorage.removeItem(config.recentPlatformStorageItem)
  localStorage.removeItem(config.savedPlatformStorageItem)
  localStorage.setItem('DOCS_CACHE_VERSION', '1')
}

export const store = configureStore()

watchOnlineState(store)

ReactDOM.render(<Root store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
